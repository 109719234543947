import React, { useEffect, useCallback, useState } from "react";
import ProductInfo from "./product-information";
import { Button, Spinner } from "@chakra-ui/react";
import Breadcrumb from "../../components/common/breadcrumb";
import "./style.css";
import StickerPDF from "../helpers/stickerGenerator";
import { useParams } from "react-router-dom";
import navguard from "../auth/navguard";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetail,
  getRequestDetail,
} from "../../store/actions/order-list";
import { isEmpty } from "lodash";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import InvoiceOrderPdf from "../helpers/invoiceOrderPdf";
import PackingSlipPdf from "../helpers/packingSlipPdf";

const OrderDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const orderDetail = useSelector(({ orders }) => orders.orderDetail);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    dispatch(getOrderDetail(id));
    dispatch(getRequestDetail(id));
  }, [id, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDownloadInvoice = async (type) => {
    setIsLoading(true);
    try {
      let pdf = new jsPDF("p", "in", [8.5, 12]);
      const canvas = await html2canvas(
        document.querySelector(
          `#${type}-${orderDetail.invoiceNumber.replace(" ", "")}`
        ),
        {
          scale: 1,
        }
      );
      let imgData = canvas.toDataURL("image/png", 0.98);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      let position = 0;
      let heightLeft = pdfHeight;
      heightLeft -= pdf.internal.pageSize.getHeight();

      pdf.addImage(
        imgData,
        "PNG",
        0,
        position,
        pdfWidth,
        pdfHeight,
        undefined,
        "FAST"
      );

      while (heightLeft >= 0) {
        position = heightLeft - pdfHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          0,
          position,
          pdfWidth,
          pdfHeight,
          undefined,
          "FAST"
        );
        heightLeft -= pdf.internal.pageSize.getHeight();
      }
      pdf.save(`${type}-${orderDetail.invoiceNumber}.pdf`);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 12,
          }}>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Spinner color="white" size="lg" />
          </div>
        </div>
      )}
      {!isEmpty(orderDetail) && (
        <>
          <div
            id={`Invoice-${orderDetail.invoiceNumber.replace(" ", "")}`}
            style={{
              backgroundColor: "#FFFFFF",
              width: "210mm",
              marginLeft: "auto",
              marginRight: "auto",
              position: "absolute",
              zIndex: "-1",
              left: "-100vw",
              top: "0",
            }}>
            <InvoiceOrderPdf data={orderDetail} />
          </div>
          <div
            id={`Packing-Slip-${orderDetail.invoiceNumber.replace(" ", "")}`}
            style={{
              backgroundColor: "#FFFFFF",
              width: "210mm",
              marginLeft: "auto",
              marginRight: "auto",
              position: "absolute",
              zIndex: "-1",
              left: "-100vw",
              top: "0",
            }}>
            <PackingSlipPdf data={orderDetail} />
          </div>
        </>
      )}
      <Breadcrumb
        title={`Order Detail ${orderDetail?.invoiceNumber}`}
        parent="Order Detail"
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 12,
          }}>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Spinner color="white" size="lg" />
          </div>
        </div>
      )}
      <div className="generate-pdf">
        <Button
          colorScheme="teal"
          mr={3}
          onClick={() => handleDownloadInvoice("Invoice")}
          isDisabled={orderDetail.orderStatus === "payment pending"}>
          Download Invoice
        </Button>
        <Button
          style={{ marginRight: "1rem" }}
          onClick={() => StickerPDF(orderDetail)}
          colorScheme="teal"
          size="md"
          isDisabled={
            orderDetail.orderStatus !== "processed" &&
            orderDetail.orderStatus !== "in shipping"
          }>
          Download Sticker
        </Button>
        <Button
          onClick={() => handleDownloadInvoice("Packing-Slip")}
          colorScheme="teal"
          size="md"
          isDisabled={
            orderDetail.orderStatus !== "processed" &&
            orderDetail.orderStatus !== "to process" &&
            orderDetail.orderStatus !== "in shipping"
          }>
          Download Packing Slip
        </Button>
      </div>
      <ProductInfo order={orderDetail} />
    </>
  );
};

export default navguard(OrderDetail);
