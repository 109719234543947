import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.css";
import {
  Box,
  Flex,
  Image,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import JsBarcode from "jsbarcode";
import { Col, Row } from "reactstrap";
import pbplogo from "../../../assets/images/logo-pbp.png";
import { isEmpty } from "lodash";

function PackingSlipPdf({ data }) {
  async function toDataURL(url) {
    return new Promise((resolve, reject) => {
      try {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = () => {
          reject("");
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      } catch {
        reject("");
      }
    });
  }

  const [shippingIcon, setShippingIcon] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    try {
      setData();
    } finally {
      setPageBreak();
    }
  }, [data]);

  async function setData() {
    if (
      data?.shippingVendorData?.awb &&
      data?.shippingVendorData?.awb !== "-"
    ) {
      JsBarcode(
        `#inv-${data.shippingVendorData.awb}`,
        data.shippingVendorData.awb,
        {
          format: "CODE128",
          width: 1,
          height: 20,
          displayValue: false,
        }
      );
    }

    const products = !isEmpty(data?.orderDetail)
      ? data.orderDetail.map((item) => {
          return {
            name: `${item.name}${
              item?.productType === "Variant"
                ? ` - ${item?.selectedVariant?.name}`
                : ""
            }`,
            barcode: item.productType.includes("Variant")
              ? item?.selectedVariant?.barcode
              : item.barcode,
            odooCode: item.productType.includes("Variant")
              ? item?.selectedVariant?.odooCode
              : item?.odooCode,
            qty: `${item.qty} pcs`,
          };
        })
      : [];
    setTableData([...products]);

    const shippingIconBlob = await toDataURL(data?.shippingMethod?.iconPath);
    setShippingIcon(shippingIconBlob);
  }

  // set page break
  const tbodyRef = useRef(null);
  function setPageBreak() {
    setTimeout(() => {
      const headerHeight = +document.querySelector?.("#header-info")?.offsetHeight || 200.
      const trs = tbodyRef.current?.querySelectorAll?.("tr");
      const pageIndex = [];
      trs?.forEach?.((element, index) => {
        // cleanup first
        element.removeAttribute("data-height");
        element.removeAttribute("data-sum-height");
        element.removeAttribute("data-page");
        element.querySelectorAll?.("td")?.forEach?.(td => {
          td.removeAttribute("style");
        });

        const currentHeight = +(element?.offsetHeight || 0);
        element.setAttribute("data-height", currentHeight);
  
        const prevElement = trs[index - 1];
        const currentSumHeight = +(prevElement?.getAttribute?.("data-sum-height") || prevElement?.offsetHeight || headerHeight) + currentHeight;
        element.setAttribute("data-sum-height", currentSumHeight);
  
        const baseHeightPerPage = 923;
        const prevPage = +prevElement?.getAttribute("data-page");
        let currentPage = +prevPage || 1;
        
        if (+currentSumHeight > (baseHeightPerPage * currentPage)) { 
          const isNextPage = currentSumHeight > baseHeightPerPage * currentPage && prevPage < (currentPage + 1);
          if (isNextPage) {
            // iterate previous elements to get all previous page (-1)
            let prevSumHeight = 0;
            if (!!pageIndex.length) {
              const firstIndex = pageIndex?.[currentPage - 2];
              for(let i = (!!firstIndex ? (firstIndex + 1) : 0); i < (index - 1); i += 1) {
                const currentElementToSum = trs[i];
                prevSumHeight += +(currentElementToSum?.getAttribute?.("data-height") || 0);
              }
            } else {
              prevSumHeight = +prevElement?.getAttribute?.("data-sum-height") || 0;
            }
  
            // HACKS(Arina): need to differentiate between first page and the next,
            // because the next pages already have the break from the previous pages,
            // unlike the first page
            const prevBaseHeightPerPage = currentPage > 1 ? 800 : 870;
            const difference = prevBaseHeightPerPage - prevSumHeight;
            element.querySelectorAll?.("td")?.forEach?.(td => {
              td.setAttribute("style", `padding-top: ${220 + (difference > 0 ? difference : 0)}px !important`);
            })
  
            currentPage += 1;
            pageIndex.push(index - 1);
          }
        }
  
        element.setAttribute("data-page", currentPage);
      });
    }, 500);
  }

  return (
    <Box className={styles["invoice-container"]}>
      <Box id="header-info">
        <Flex className={styles["invoice-header"]}>
          <Box>Invoice {data.invoiceNumber}</Box>
          <Spacer />
          {!data.isDropship && <Image src={pbplogo} height={"72px"} />}
        </Flex>
        <Text className={styles["invoice-title"]}>Informasi Pesanan</Text>
        <Box className={styles["invoice-divider"]} />
        <Row>
          <Col xs={4}>
            <Box className={styles["invoice-info-section"]}>
              <Text className={styles["invoice-info-title"]}>
                Order Datetime:
              </Text>
              <Text className={styles["invoice-info-desc"]}>
                {data.orderDate}
              </Text>
            </Box>
            <Box className={styles["invoice-info-section"]}>
              <Text className={styles["invoice-info-title"]}>Status:</Text>
              <Text className={styles["invoice-info-desc"]}>
                {data.orderStatus}
              </Text>
            </Box>
            <Box className={styles["invoice-info-section"]}>
              <Text className={styles["invoice-info-title"]}>Email:</Text>
              <Text className={styles["invoice-info-desc"]}>
                {data.user?.email}
              </Text>
            </Box>
          </Col>
          <Col xs={4}>
            <Box className={styles["invoice-info-section"]}>
              <Text className={styles["invoice-info-title"]}>Penerima:</Text>
              <Text className={styles["invoice-info-desc"]}>
                {data?.shippingAddress?.recipientName}
              </Text>
              <Text className={styles["invoice-info-desc"]}>
                {data?.shippingAddress?.fullAddress}
              </Text>
              <Text className={styles["invoice-info-desc"]}>
                {data?.shippingAddress?.address?.urban},{" "}
                {data?.shippingAddress?.address?.district},{" "}
                {data?.shippingAddress?.address?.city},{" "}
                {data?.shippingAddress?.address?.province},{" "}
                {data?.shippingAddress?.address?.postalCode}
              </Text>
              <Text className={styles["invoice-info-desc"]}>
                Telp. {data?.shippingAddress?.recipientPhone}
              </Text>
            </Box>
            <Box className={styles["invoice-info-section"]}>
              <Text className={styles["invoice-info-title"]}>Note:</Text>
              <Text className={styles["invoice-info-desc"]}>
                {data?.shippingAddress?.note}
              </Text>
            </Box>
          </Col>
          <Col xs={4}>
            <Box className={styles["invoice-info-section"]}>
              <Text className={styles["invoice-info-title"]}>Pengirim:</Text>
              {data?.isDropship ? (
                <>
                  <Text className={styles["invoice-info-desc"]}>
                    {data?.senderAddress?.recipientName}
                  </Text>
                  <Text className={styles["invoice-info-desc"]}>
                    {data?.senderAddress?.fullAddress}
                  </Text>
                  <Text className={styles["invoice-info-desc"]}>
                    {data?.senderAddress?.address?.urban},{" "}
                    {data?.senderAddress?.address?.district},{" "}
                    {data?.senderAddress?.address?.city},{" "}
                    {data?.senderAddress?.address?.province},{" "}
                    {data?.senderAddress?.address?.postalCode}
                  </Text>
                  <Text className={styles["invoice-info-desc"]}>
                    Telp. {data?.senderAddress?.recipientPhone}
                  </Text>
                </>
              ) : (
                <>
                  <Text className={styles["invoice-info-desc"]}>
                    {data?.warehouse?.warehouseName}
                  </Text>
                  <Text className={styles["invoice-info-desc"]}>
                    {data?.warehouse?.addressDetail}
                  </Text>
                  <Text className={styles["invoice-info-desc"]}>
                    Telp. {data?.warehouse?.phone}
                  </Text>
                </>
              )}
            </Box>
            <Box className={styles["invoice-info-section"]}>
              <Text className={styles["invoice-info-title"]}>Berat Pesanan:</Text>
              <Text className={styles["invoice-info-desc"]}>
                {data.totalWeight} Kg
              </Text>
            </Box>
          </Col>
        </Row>
        <Box className={styles["invoice-divider"]} />
        <Row>
          <Col xs={4}>
            <Box className={styles["invoice-info-section"]}>
              <Text className={styles["invoice-info-title"]}>
                Metode Pesanan:
              </Text>
              <Text className={styles["invoice-info-desc"]}>
                {data.shippingMethodId}
              </Text>
            </Box>
            <Image width={"60%"} src={shippingIcon} />
          </Col>
          <Col xs={4}>
            <Box className={styles["invoice-info-section"]}>
              <Text className={styles["invoice-info-title"]}>Nomor AWB:</Text>
              <Text className={styles["invoice-info-desc"]}>
                {data.shippingVendorData.awb}
              </Text>
            </Box>
            {data.shippingVendorData.awb && (
              <Image id={`inv-${data.shippingVendorData.awb}`} />
            )}
          </Col>
        </Row>
        <Box className={styles["invoice-empty-space"]} />
        <Text className={styles["invoice-title"]}>Informasi Produk</Text>
        <Box className={styles["invoice-divider"]} />
      </Box>
      <TableContainer>
        <Table variant="simple" className={styles["invoice-table"]}>
          <Thead>
            <Tr>
              <Th className={styles["invoice-info-title"]}>Nama Produk</Th>
              <Th className={styles["invoice-info-title"]}>Odoo</Th>
              <Th className={styles["invoice-info-title"]}>Barcode</Th>
              <Th className={styles["invoice-info-title"]}>Jumlah</Th>
            </Tr>
          </Thead>
          <Tbody ref={tbodyRef}>
            {tableData.map((data, idx) => (
              <Tr key={idx}>
                <Td>
                  <div className={styles["invoice-product-name"]}>
                    {data.name}
                  </div>
                </Td>
                <Td>{data?.odooCode}</Td>
                <Td>{data.barcode}</Td>
                <Td>{data.qty}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PackingSlipPdf;
